import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { StyledH1, StyledSectionWrapper } from "../theme/styles";
import styled from "styled-components";
import t from "../theme/theme";

const StyledNewsList = styled.ul`
  padding-top: 15vh;
  list-style-type: none;
  padding-left: 0;
  padding-right: ${t.spacing(2)};
`;

const StyledNewsItem = styled.li`
  margin-bottom: ${t.spacing(3)};
  padding-bottom: ${t.spacing(2)};
  border-bottom: 1px solid ${t.text};
  p {
    margin: 0;
  }
`;

const StyledTitle = styled.h3`
  font-size: ${t.spacing(2)};
  font-weight: normal;
`;

const StyledMarkdown = styled(ReactMarkdown)`
  color: ${t.blue};
`;

const NewsPage = (props) => {
  const data = props.data.allFile.edges;
  return (
    <Layout location={props.location}>
      <StyledSectionWrapper>
        <StyledH1>News</StyledH1>
        <StyledNewsList>
          {data.map((news) => (
            <StyledNewsItem key={news.node.name}>
              <time dateTime={news.node.childMarkdownRemark.frontmatter.date}>
                {news.node.childMarkdownRemark.frontmatter.date}
              </time>
              <StyledTitle>
                {news.node.childMarkdownRemark.frontmatter.title}
              </StyledTitle>
              <StyledMarkdown
                children={news.node.childMarkdownRemark.rawMarkdownBody}
              />
            </StyledNewsItem>
          ))}
        </StyledNewsList>
      </StyledSectionWrapper>
    </Layout>
  );
};

export default NewsPage;

export const pageQuery = graphql`
  query newsQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "news" } }
      sort: { fields: childrenMarkdownRemark___frontmatter___date, order: ASC }
    ) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              date
            }
            rawMarkdownBody
          }
        }
      }
    }
  }
`;
